import logo from './logo.svg';
import './App.css';
import AboutUs from './Pages/AboutUs';
import Services from './Pages/Services';
import { BrowserRouter, Switch, Route, Routes, Link } from 'react-router-dom';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import Header2 from './components/Header2';
import Header from './components/Header';
import Computing from './Pages/Computing';
import Consulting from './Pages/Consulting';
import Projects from './Pages/Projects';
import Hosting from './Pages/Hosting';
import Maintenance from './Pages/Maintenance ';
import Network from './Pages/Network ';
import Programming from './Pages/Programming ';
import Software from './Pages/Software';
import AOS from 'aos';
import Footer from './components/Footer';
function App() {
  AOS.init();

  return (
    <div className="App">
      <header className="App-header">
      <BrowserRouter basename="/">
        <Header2 />
          <Routes>
              <Route index element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/computing" element={<Computing />} />
              <Route path="/consulting" element={<Consulting />} />
              <Route path="/hosting" element={<Hosting />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/network" element={<Network />} />
              <Route path="/programming" element={<Programming />} />
              <Route path="/software" element={<Software />} />
          </Routes>
        </BrowserRouter>
      </header>

    </div>
  );
}

export default App;

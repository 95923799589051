import React from 'react'
import ServicesContent from '../components/Services';
import Footer from '../components/Footer';
import Faq from '../components/Faq';
import { useTranslation } from 'react-i18next';

function Services() {
    const { t } = useTranslation();

  return (
    <div>
        <div className="services">
            <div>
                <div className="title">
                    <h1 className="text-white bold">{t('our-services-title')}</h1>
                    <p>{t('our-service-paragraph')}</p>
                </div>
            </div>

            <div style={{marginTop: "200px"}}>
                <ServicesContent />
            </div>

            {/* <div style={{marginTop: "200px"}}>
                <Faq />
            </div> */}

            <div style={{marginTop: "100px"}}>
                <Footer />
            </div>
        
        </div>
    </div>
  )
}

export default Services
import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,ServerIcon, CodeBracketIcon, ComputerDesktopIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import logo from "../images/logo.png";
import LanguageSelector from './LanguageSelector';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const services = [
    { name: 'Computing', description: 'Servers,Workstation,PCs,Hybrid Loptop.Laptop, Tablet , Storage,Rack and Powers, Projector, Printer&Copires, Routers, Switches,Cam', href: '/computing', icon: ChartPieIcon },
    { name: 'Consulting', description: 'Hardware, Software,Network,Designs, Marketing', href: '/consulting', icon: CursorArrowRaysIcon },
    { name: 'Hosting', description: "Domains,Shared Hosting, Email&Office,Web Security", href: '/hosting', icon: ServerIcon },
    { name: 'Maintenance', description: "Hardware,Software,Network, Business IT Support", href: '/maintenance', icon: SquaresPlusIcon },
    { name: 'Network', description: 'Infrastructure, Data Center Preparation,Security,Cloud', href: '/network', icon: ArrowPathIcon },
    { name: 'Programming', description: 'System Development, Website Development, Mobile Development, Database Development', href: '/programming', icon: CodeBracketIcon },
    { name: 'Software', description: 'Operation Systems, Products, Security Systems , Camera Systems, Alarm Systems', href: '/software', icon: ComputerDesktopIcon },
]

const languages = [
    { name: 'English', img: ""},
    { name: 'German', img: ""},
    { name: 'Albanian', img: ""},
    
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header2() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { t } = useTranslation();

  return (
    <header className="bg-white">
      <nav className="mx-auto flex max-w-7xl justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-6 w-auto" src={logo} alt="" />
          </a>
        </div>
        <div className="flex items-center lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative flex items-center">
            <Link to="/" className="text-sm mx-5 font-semibold leading-6 text-gray-900">
                {t('header-link-1')}
            </Link>
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900" style={{marginTop: "-0px", outline: "0"}}>
              {t('header-link-3')}
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {services.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <Link to={item.href} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <Link to="/about" className="text-sm font-semibold leading-6 text-gray-900">
          {t('header-link-2')}
          </Link>
          <Link to="/projects" className="text-sm font-semibold leading-6 text-gray-900">
          {t('header-link-4')}
            </Link>
          <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900">
          {t('header-link-5')}
          </Link>
          <Link style={{marginTop: "3px"}}>
            <LanguageSelector />
          </Link>
        </Popover.Group>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-6 w-auto"
                src={logo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50" style={{outline: "0"}}>
                        {t('header-link-3')}
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...services].map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t('header-link-1')}
                </Link>
                <Link
                  to="/about"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t('header-link-2')}
                </Link>
                <Link
                  to="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  {t('header-link-5')}
                </Link>
                <Link className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  <LanguageSelector />
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();
  return (
    <section style={{background: "#BC3424"}}>
    <div class="max-w-3xl px-6 py-16 mx-auto text-center">
        <h1 class="text-3xl font-semibold text-white dark:text-white-100">{t('awesome-things-title')}</h1>
        <p class="max-w-md mx-auto mt-5 text-white-500 dark:text-white-400">{t('awesome-things-paragraph')}</p>

        <div class="flex flex-col mt-8 space-y-3 sm:space-y-0 sm:flex-row sm:justify-center sm:-mx-2" data-aos="flip-right">
            <input id="email" type="text" class="px-4 py-2 text-gray-700 bg-white border rounded-md sm:mx-2 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" placeholder="Email Address" />

            <button class="px-4 py-2 text-sm font-medium bg-white tracking-wide text-black capitalize transition-colors duration-300 transform bg-blue-700 rounded-md sm:mx-2 hover:bg-blue-600 focus:outline-none">
                {t('banner-getStarted-btn')}
            </button>
        </div>
    </div>
</section>
  )
}

export default Contact
import React from 'react'
import about from "../images/about-us.png";
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div>
        <section class="" data-aos="fade-up">
            <div class="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
                <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-8">
                    <div class="max-w-lg">
                        <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">{t('about-us-title')}</h2>
                        <p class="mt-4 text-gray-600 text-lg">{t('about-us-paragraph')}</p>
                        
                    </div>
                    <div class="mt-12 md:mt-0">
                        <img src={about} alt="About Us Image" class="object-cover rounded-lg shadow-md" />
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AboutUs
import React from 'react'
import { useTranslation } from 'react-i18next';

function TellUs() {
  const { t } = useTranslation();

  return (
    <div style={{background: "#BC3424"}}>
    <div class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
        <div class="px-6 py-6 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
            <div class="xl:w-0 xl:flex-1">
                <h2 class="text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl sm:leading-9">
                    {t('tell-us')}
                </h2>
                <p class="max-w-3xl mt-3 text-lg leading-6 text-white-200" id="newsletter-headline">
                    {t('tell-us-paragraph')}
                </p>
            </div>
            
        </div>
    </div>
</div>
  )
}

export default TellUs
import React from 'react'
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

function Hosting() {
    const { t } = useTranslation();
  return (
    <div>
        <div className="hosting">
            <div>
                <div className="title">
                    <h1 className="text-white bold">{t('hosting')}</h1>
                    <p>{t('hosting-paragraph')}</p>
                </div>
            </div>
        <div>
            <section class="bg-white dark:bg-gray-900" style={{marginTop: "100px"}}>
                <div class="container px-6 py-10 mx-auto">
                    <h1 class="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl dark:text-white">explore our <br /> awesome Components</h1>

                    <div class="mt-2">
                        <span class="inline-block w-40 h-1 rounded-full" style={{background: "#BC3424"}}></span>
                        <span class="inline-block w-3 h-1 ml-1 rounded-full" style={{background: "#BC3424"}}></span>
                        <span class="inline-block w-1 h-1 ml-1 rounded-full" style={{background: "#BC3424"}}></span>
                    </div>

                    <div class="mt-8 xl:mt-12 lg:flex lg:items-center">
                        <div class="grid w-full grid-cols-1 gap-8 lg:w-1/2 xl:gap-16 md:grid-cols-2">
                            <div class="space-y-3">

                                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">{t('title-1')}</h1>

                                <p class="text-gray-500 dark:text-gray-300">
                                    {t('text-1')}
                                </p>
                            </div>

                            <div class="space-y-3">
                                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">{t('title-2')}</h1>

                                <p class="text-gray-500 dark:text-gray-300">
                                {t('text-2')}</p>
                            </div>

                            <div class="space-y-3">
                                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">{t('title-3')}</h1>

                                <p class="text-gray-500 dark:text-gray-300">
                                {t('text-3')}</p>
                            </div>

                            <div class="space-y-3">
                                <h1 class="text-xl font-semibold text-gray-700 capitalize dark:text-white">{t('title-4')}</h1>
                                <p class="text-gray-500 dark:text-gray-300">
                                {t('text-4')}</p>
                            </div>
                        </div>

                        <div class="hidden lg:flex lg:w-1/2 lg:justify-center">
                            <img class="w-[28rem] h-[28rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src="https://bargussbatistic.com/wp-content/uploads/2020/04/Barguss-Batistic-Blog-What-is-a-Web-Host.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </div>
    </div>
  )
}

export default Hosting
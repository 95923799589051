import React from 'react'
import Header from '../components/Header';
import Banner from '../components/Banner';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import FAQ from '../components/Faq';
import AboutUs from '../components/AboutUs';
import TellUs from '../components/TellUs';
import WhyChooseUs from '../components/WhyChooseUs';
import Location from '../components/Location';
import AccordionContent  from '../components/AccordionContent';
import Faq from '../components/Faq';
import Brands from '../components/Brands';
import Brands2 from '../components/Brands2';

function Home() {
  return (
    <div>
        <div>
            <Banner />
        </div>
        <div style={{marginTop: "100px"}}>
            <AboutUs />
        </div>
        <div style={{marginTop: "100px"}}>
            <Services />
        </div>
        <div style={{marginTop: "100px"}}>
            <TellUs />
        </div>
        <div style={{marginTop: "100px"}}>
            <WhyChooseUs />
        </div>
        <div style={{marginTop: "100px"}}>
            <Contact />
        </div>
        {/* <div style={{marginTop: "100px"}}>
            <Faq />
        </div> */}
        <div style={{marginTop: "100px"}}>
            <Brands2 />
        </div>
        <div style={{marginTop: "100px"}}>
            <Location />
        </div>
        <div style={{marginTop: "100px"}}>   
            <Footer />
        </div>
    </div>
  )
}

export default Home
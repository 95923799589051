import React, { useState } from 'react'
import img1 from "../images/small-lab-oil.png";
import img2 from "../images/kameleoni.png";
import img3 from "../images/diamant-pizza.jpg";
import img4 from "../images/rentacarbe.jpg";
import img5 from "../images/be-connected.jpg";

function Brands2() {

  return (
    <div>
        <div className="title">
            <h1 className="text-center mb-5">Partners</h1>
        </div>
        <div className="flex flex-wrap items-center justify-center container">
        <div class="flex flex-wrap items-center justify-center m-2" data-aos="flip-left">
            <div style={{width: "350px", height: "400px"}} class="container lg:w-2/6 xl:w-2/7 sm:w-full md:w-2/3  bg-white  shadow-lg  transform   duration-200 easy-in-out">
                <div class="h-32 overflow-hidden">
                    <img class="w-full" src="https://laboil-ks.com/wp-content/uploads/2021/06/IMG_3662_1622094996.jpg" alt="" />
                </div>
                <div class="flex justify-center px-5  -mt-12">
                    <img class="w-20 bg-white p-2 rounded-full" src={img1} alt="" />
                </div>
                <div class="">
                    <div class="text-center px-14">
                        
                        <ul className="" style={{listStyle: "disc", textAlign: "left", fontSize: "14px"}}>
                            <li className="m-3">Fush Kosove</li>
                            <li className="m-3" >Prishtine</li>
                            <li className="m-3" >Therand, Shiroke</li>
                            <li className="m-3" >Komoran, Njesia 1</li>
                            <li className="m-3" >Komoran, Njesia 2</li>
                            <li className="m-3" >Therand, Reshton</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap items-center justify-center m-2" data-aos="flip-right">
            <div style={{width: "350px", height: "400px"}} class="container lg:w-2/6 xl:w-2/7 sm:w-full md:w-2/3  bg-white  shadow-lg  transform   duration-200 easy-in-out">
                <div class=" h-32 overflow-hidden" style={{backgroundColor: "#232B04"}}>
                    {/* <img class="w-full" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQcCp6q2umR7gCdeeNbOhzTVHMgK-YoVHKzwZ0HLVPEyH1lvFxKFqEcaPoTWtr4IeXQwo&usqp=CAU" alt="" /> */}
                </div>
                <div class="flex justify-center px-5  -mt-12">
                    <img class="bg-white p-2 rounded-full" style={{width: "80px", height: "80px"}} src={img2} alt="" />
                </div>
                <div class=" ">
                    <div class="text-center px-14">
                  
                        <ul className="" style={{listStyle: "disc", textAlign: "left", fontSize: "14px"}}>
                            <li className="m-3">Fush Kosove</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap items-center justify-center m-2" data-aos="flip-left">
            <div style={{width: "350px", height: "400px"}} class="container lg:w-2/6 xl:w-2/7 sm:w-full md:w-2/3  bg-white  shadow-lg  transform   duration-200 easy-in-out">
                <div class=" h-32 overflow-hidden" >
                    <img class="w-full" src="https://scontent.fprn12-1.fna.fbcdn.net/v/t1.6435-9/160850619_3858339087558959_4522953031389966092_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=7f8c78&_nc_ohc=KV-ReRY3isgAX_Sz4OH&_nc_ht=scontent.fprn12-1.fna&oh=00_AfB_d57PyM46ZEYJmEj35TaFWSqvkfJTpR06rD3BP8B6Jg&oe=65687678" alt="" />
                </div>
                <div class="flex justify-center px-5  -mt-12">
                    <img class="w-20 bg-white p-2 rounded-full" src={img3} alt="" />
                </div>
                <div class=" ">
                    <div class="text-center px-14">
                  
                        <ul className="" style={{listStyle: "disc", textAlign: "left", fontSize: "14px"}}>
                            <li className="m-3">Drenas</li>
                            <li className="m-3" >Komoran</li>
                            <li className="m-3" >Skenderaj</li>
                            <li className="m-3">Fushe Kosove</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap items-center justify-center m-2" data-aos="flip-left">
            <div style={{width: "350px", height: "400px"}} class="container lg:w-2/6 xl:w-2/7 sm:w-full md:w-2/3  bg-white  shadow-lg  transform   duration-200 easy-in-out">
                <div class=" h-32 overflow-hidden" >
                    <img class="w-full" src={img4} alt="" />
                </div>
                <div class="flex justify-center px-5  -mt-12">
                    <img class="w-20 bg-white p-2 rounded-full" src={img4} alt="" />
                </div>
                <div class=" ">
                    <div class="text-center px-14">
                      
                        <ul className="" style={{listStyle: "disc", textAlign: "left", fontSize: "14px"}}>
                            <li className="m-3">Airoporti i Prishtinë</li>
                            <li className="m-3" >Rruga Ferid Curri (Arbëri)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap items-center justify-center m-2" data-aos="flip-right">
            <div style={{width: "350px", height: "400px"}} class="container lg:w-2/6 xl:w-2/7 sm:w-full md:w-2/3  bg-white  shadow-lg  transform   duration-200 easy-in-out">
                <div class=" h-32 overflow-hidden" style={{backgroundColor: "#8BBB37"}}>
                    {/* <img class="" src={img5} alt="" /> */}
                </div>
                <div class="flex justify-center px-5  -mt-12">
                    <img class="w-20 bg-white p-2 rounded-full" src={img5} alt="" />
                </div>
                <div class="">
                    <div class="text-center px-14">
                        <ul className="" style={{listStyle: "disc", textAlign: "left", fontSize: "14px"}}>
                            <li className="m-3" >Prishtine</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Brands2
import React from 'react'
import About from '../components/AboutUs';
import WhyChooseUs from '../components/WhyChooseUs';
import Location from '../components/Location';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className="about">
        <div>
            <div className="title">
                <h1 className="text-white bold">{t('about-us-title')}</h1>
                <p>{t('about-us-intro-text')}</p>
            </div>
        </div>
        <div style={{marginTop: "200px"}}>
            <About />
        </div>

        <div style={{marginTop: "100px"}}>
            <WhyChooseUs />
        </div>

        <div style={{marginTop: "100px"}}>
            <Location />
        </div>

        <div style={{marginTop: "100px"}}>
            <Footer />
        </div>
        
    </div>
  )
}

export default AboutUs
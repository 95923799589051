import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Services() {
  const { t } = useTranslation();
  
  return (
    <section>
      <div>
        <h1 className="text-center" style={{fontSize: "40px", fontWeight: "bold"}} data-aos="fade-up">{t('our-services-title')}</h1>
        <p data-aos="fade-down" className="text-dark text-center">{t('our-service-paragraph')}</p>
      </div>
    <div className="pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] flex justify-center flex-wrap" data-aos="fade-up">
      
      <Link to="/services" style={{textDecoration: "none"}}>
        <div class="relative service flex flex-col justify-center">
            <div style={{height: "320px", margin: "10px"}}
              class="group relative cursor-pointer overflow-hidden px-6 pt-10 pb-8 shadow-xl ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
              <span class="absolute top-10 z-0 h-20 w-20 rounded-full transition-all duration-300 group-hover:scale-[10]" style={{background: "#BC3424"}}></span>
              <div class="relative mx-auto max-w-md" >
                  <span class="grid h-20 w-20 place-items-center rounded-full transition-all duration-300 group-hover:bg-white-400">
                      {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10 text-white transition-all">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                      </svg> */}
                      <i className="fa fa-laptop text-white" style={{fontSize: "35px"}}></i>
                  </span>
                  <div>
                    <h1 class="mt-3 text-bold" style={{fontWeight: "bolder"}}>{t('service-1')}</h1>
                  </div>
                  <div className="service-text">
                    <p>
                      {t('service-1-text')}
                    </p>
                  </div>
              </div>
          </div>
        </div>
      </Link>
    <Link to="/services" style={{textDecoration: "none"}}>
      <div class="relative service flex flex-col justify-center">
          <div style={{height: "320px", margin: "10px"}}
            class="group relative cursor-pointer overflow-hidden px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span class="absolute top-10 z-0 h-20 w-20 rounded-full transition-all duration-300 group-hover:scale-[10]" style={{background: "#BC3424"}}></span>
            <div class="relative mx-auto max-w-md" >
                <span class="grid h-20 w-20 place-items-center rounded-full transition-all duration-300 group-hover:bg-white-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10 text-white transition-all">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg>
                </span>
                <div>
                    <h1 class="mt-3 text-bold" style={{fontWeight: "bolder"}}>{t('service-2')}</h1>
                  </div>
                  <div className="service-text">
                    <p>
                      {t('service-2-text')}
                    </p>
                  </div>
            </div>
        </div>
      </div>
    </Link>

    <Link to="/services" style={{textDecoration: "none"}}>
      <div class="relative service flex flex-col justify-center">
          <div style={{height: "320px", margin: "10px"}}
            class="group relative cursor-pointer overflow-hidden px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span class="absolute top-10 z-0 h-20 w-20 rounded-full transition-all duration-300 group-hover:scale-[10]" style={{background: "#BC3424"}}></span>
            <div class="relative mx-auto max-w-md" >
                <span class="grid h-20 w-20 place-items-center rounded-full transition-all duration-300 group-hover:bg-white-400">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10 text-white transition-all">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg> */}
                    <i className="fa fa-server text-white" style={{fontSize: "35px"}}></i>
                </span>
                <div>
                    <h1 class="mt-3 text-bold" style={{fontWeight: "bolder"}}>{t('service-3')}</h1>
                  </div>
                  <div className="service-text">
                    <p>
                      {t('service-3-text')}
                    </p>
                </div>
            </div>
        </div>
      </div>
    </Link>

    <Link to="/services" style={{textDecoration: "none"}}>
      <div class="relative service flex flex-col justify-center">
          <div style={{height: "320px", margin: "10px"}}
            class="group relative cursor-pointer overflow-hidden px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span class="absolute top-10 z-0 h-20 w-20 rounded-full transition-all duration-300 group-hover:scale-[10]" style={{background: "#BC3424"}}></span>
            <div class="relative mx-auto max-w-md">
                <span class="grid h-20 w-20 place-items-center rounded-full transition-all duration-300 group-hover:bg-white-400 text-white">
                <i className="fa fa-gear text-white" style={{fontSize: "35px"}}></i>

                    {/* <i className="fab fab-network-wired text-white" style={{fontSize: "35px"}}></i> */}
                </span>
                <div>
                    <h1 class="mt-3 text-bold" style={{fontWeight: "bolder"}}>{t('service-4')}</h1>
                  </div>
                  <div className="service-text">
                    <p>
                      {t('service-4-text')}
                    </p>
                </div>
            </div>
        </div>
      </div>
    </Link>
    
    <Link to="/services" style={{textDecoration: "none"}}>
      <div class="relative service flex flex-col justify-center">
          <div style={{height: "320px", margin: "10px"}}
            class="group relative cursor-pointer overflow-hidden px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span class="absolute top-10 z-0 h-20 w-20 rounded-full transition-all duration-300 group-hover:scale-[10]" style={{background: "#BC3424"}}></span>
            <div class="relative mx-auto max-w-md" >
                <span class="grid h-20 w-20 place-items-center rounded-full transition-all duration-300 group-hover:bg-white-400">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10 text-white transition-all">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg>
                </span>
                <div>
                    <h1 class="mt-3 text-bold" style={{fontWeight: "bolder"}}>{t('service-5')}</h1>
                  </div>
                  <div className="service-text">
                    <p>
                      {t('service-5-text')}
                    </p>
                </div>
            </div>
        </div>
      </div>
    </Link>

    <Link to="/services" style={{textDecoration: "none"}}>
      <div class="relative service flex flex-col justify-center">
          <div style={{height: "320px", margin: "10px"}}
            class="group relative cursor-pointer overflow-hidden px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span class="absolute top-10 z-0 h-20 w-20 rounded-full transition-all duration-300 group-hover:scale-[10]" style={{background: "#BC3424"}}></span>
            <div class="relative mx-auto max-w-md" >
                <span class="grid h-20 w-20 place-items-center rounded-full transition-all duration-300 group-hover:bg-white-400">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10 text-white transition-all">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg> */}
                    <i class="fa fa-code text-white" style={{fontSize: "35px"}}></i>
                </span>
                <div>
                    <h1 class="mt-3 text-bold" style={{fontWeight: "bolder"}}>{t('service-6')}</h1>
                  </div>
                  <div className="service-text">
                    <p>
                      {t('service-6-text')}
                    </p>
                </div>
            </div>
        </div>
      </div>
    </Link>

    <Link to="/services" style={{textDecoration: "none"}}>
      <div class="relative service flex flex-col justify-center">
          <div style={{height: "320px", margin: "10px"}}
            class="group relative cursor-pointer overflow-hidden px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
            <span class="absolute top-10 z-0 h-20 w-20 rounded-full transition-all duration-300 group-hover:scale-[10]" style={{background: "#BC3424"}}></span>
            <div class="relative mx-auto max-w-md" >
                <span class="grid h-20 w-20 place-items-center rounded-full transition-all duration-300 group-hover:bg-white-400">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10 text-white transition-all">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                    </svg> */}
                <i class="fa fa-desktop text-white" style={{fontSize: "35px"}}></i>

                </span>
                <div>
                    <h1 class="mt-3 text-bold" style={{fontWeight: "bolder"}}>{t('service-7')}</h1>
                  </div>
                  <div className="service-text">
                    <p>
                      {t('service-7-text')}
                    </p>
                </div>
            </div>
        </div>
      </div>
    </Link>
  </div>
    </section>
    
  )
}

export default Services
import React from 'react'
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

function Consulting() {
  const { t } = useTranslation();
  return (
    <div>
        <div className="consulting">
            <div className="container">
            <div>
                <div className="title">
                    <h1 className="text-white bold">{t('consulting')}</h1>
                    <p>{t('consulting-paragraph')}</p>
                </div>
            </div>
            <div style={{marginTop: "270px"}}>
            <p class="mb-3 text-gray-500 dark:text-gray-400">{t('first-text')}</p>
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-3">
                <p class="mb-3 text-gray-500 dark:text-gray-400">{t('second-text')}</p>
                <p class="mb-3 text-gray-500 dark:text-gray-400">{t('third-text')}</p>
                <p class="mb-3 text-gray-500 dark:text-gray-400">{t('fourth-text')}</p>
            </div>
            <p class="mb-3 text-gray-500 dark:text-gray-400">{t('fifth-text')}</p>
            </div>
            
            </div>
            {/* <Footer /> */}
        </div>

       
    </div>
  )
}

export default Consulting
import React from 'react'
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer';

function Projects() {
  const { t } = useTranslation();
  return (
    <div class="banner">
       <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-10 mx-auto">
        <h1 class="text-2xl font-semibold text-center text-black capitalize lg:text-3xl" style={{color: "black"}}>{t('our-projects')}</h1>

        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 lg:grid-cols-2">
            <div class="flex items-end overflow-hidden bg-cover rounded-lg h-96" style={{backgroundImage: "url('https://www.ipko.com/wp-content/uploads/2017/04/featuredImage.jpg')"}}>
                <div class="w-full px-8 py-4 overflow-hidden rounded-b-lg backdrop-blur-sm bg-white/60 dark:bg-gray-800/60">
                    <h2 class="mt-4 text-xl font-semibold text-gray-800 capitalize dark:text-white">Contractor by Ipko Telecommunications LLC</h2>
                    <h2 class="mt-4 text-xl  capitalize">-Implementimi dhe ndertimi i rrjetit te IPKO-s, Drenas</h2>
                </div>
            </div>

            <div class="flex items-end overflow-hidden bg-cover rounded-lg h-96" style={{backgroundImage: "url('https://www.ipko.com/wp-content/uploads/2017/04/featuredImage.jpg')"}}>
                <div class="w-full px-8 py-4 overflow-hidden rounded-b-lg backdrop-blur-sm bg-white/60 dark:bg-gray-800/60">
                    <h2 class="mt-4 text-xl font-semibold text-gray-800 capitalize dark:text-white">Contractor by Ipko Telecommunications LLC</h2>
                    <h2 class="mt-4 text-xl  capitalize">-Implementimi dhe ndertimi i rrjetit te IPKO-s, Drenas</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<Footer />
    </div>
  )
}

export default Projects
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

// const LanguageSelector = () => {
  

//   return (
//     <div>
//       <button onClick={() => changeLanguage('en')}>English</button>
//       <button onClick={() => changeLanguage('ge')}>German</button>
//       <button onClick={() => changeLanguage('al')}>Albanian</button>
//     </div>
//   );
// };

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import {
  ArrowPathIcon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline'

const languages = [
  { code: 'en', name: 'English', flag: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/800px-Flag_of_the_United_Kingdom_%281-2%29.svg.png' },
  { code: 'ge', name: 'German', flag: 'https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1200px-Flag_of_Germany.svg.png' },
  { code: 'al', name: 'Albanian', flag: 'https://cdn.britannica.com/00/6200-004-42B7690E/Flag-Albania.jpg' },
];

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Set the default language here

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };
  return (
    <Popover className="flex h-auto mt-2">
      <Popover.Button style={{outline: "0", marginTop: "-10px"}} className="inline-flex items-center gap-x-1 text-sm font-semibold  text-gray-900">
      <img
          style={{ width: '30px', cursor: 'pointer' }}
          src={languages.find((lang) => lang.code === selectedLanguage).flag}
          alt="Selected Flag"
          onClick={() => changeLanguage(selectedLanguage)}
        />
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel style={{width: "180px"}} className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
            {languages.map((lang) => (
            <div
              key={lang.code}
              className="flex items-center justify-center m-2 px-2 py-1 cursor-pointer"
              onClick={() => changeLanguage(lang.code)}
            >
              <img style={{ width: '30px' }} src={lang.flag} alt={lang.name} onClick={() => changeLanguage(lang.code)}/>
            </div>
          ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}


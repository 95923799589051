import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Banner() {
  const { t } = useTranslation();
  return (
    <div class="banner">
        {/* <main class="flex flex-1 w-full flex-col items-center justify-center text-center px-4">
          <h1 class="mx-auto max-w-4xl font-display text-5xl font-bold tracking-normal text-slate-900 sm:text-7xl">WELCOME TO
          Urban 
            <span class="relative whitespace-nowrap text-orange-400">
                <svg aria-hidden="true" viewBox="0 0 418 42" class="absolute top-2/3 left-0 h-[0.58em] w-full fill-orange-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
                <span class="relative" style={{color: "#BC3424"}}> Tech</span>
            </span>
          </h1>
          <p class="mx-auto mt-12 max-w-xl text-lg text-slate-700 leading-7">Welcome to our world of Business IT Consulting and Solutions! We are a dynamic and forward-thinking company dedicated to helping businesses thrive in the digital age. With a strong focus on technology, 
          innovation, and strategic guidance, we are your trusted 
          partner in navigating the ever-evolving landscape of IT
          </p>
          <a class="bg-black rounded-xl text-white font-medium px-4 py-3 sm:mt-10 mt-8 hover:bg-black/80" href="#" style={{background: "#BC3424"}}>{t('buttonText')}
          </a>
          <p>{t('language')}</p>
        </main> */}
        <section class="" data-aos="fade-down">
          <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">

            <div class="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
              <div className="mt-5">
                <h1 class="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight dark:text-white">{t('banner-title')}</h1>
                <p class="mt-3 text-lg text-gray-800 dark:text-gray-400">{t('banner-paragraph')}</p>
                <div class="mt-7 grid gap-3 w-full sm:inline-flex">
                  <Link to="/contact" style={{backgroundColor: "#BC3424"}} class="inline-flex justify-center items-center gap-x-3 text-center text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800" href="#">
                    {t('banner-getStarted-btn')}
                    <svg class="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </Link>
                  <a class="inline-flex justify-center items-center contact-us-btn gap-x-3.5 text-sm lg:text-base text-center text-white shadow-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:border-gray-800 dark:hover:border-gray-600 dark:shadow-slate-700/[.7] dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800" style={{border: "2px solid #BC3424"}}href="#">
                    {t('banner-contact-btn')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
  )
}

export default Banner
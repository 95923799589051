import React from 'react'
import logo from "../images/logo.png";
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation();
    
  return (
    <footer class="bg-white dark:bg-gray-900 mt-5">
      <div class="container px-6 py-12 mx-auto">
          <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              <div>
                  <p class="font-semibold text-gray-800 dark:text-white">{t('quick-links')}</p>

                  <div class="flex flex-col items-start mt-5 space-y-2">
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('header-link-1')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('header-link-2')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('header-link-3')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('header-link-4')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('header-link-5')}</a>
                  </div>
              </div>

              <div>
                  <p class="font-semibold text-gray-800 dark:text-white">{t('industries')}</p>

                  <div class="flex flex-col items-start mt-5 space-y-2">
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">Health</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">Information Technology</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">Finance & Insurance</a>
                  </div>
              </div>

              <div>
                  <p class="font-semibold text-gray-800 dark:text-white">{t('header-link-3')}</p>

                  <div class="flex flex-col items-start mt-5 space-y-2">
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">Computing</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('consulting')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('hosting')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('maintenance')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('network')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('programming')}</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">{t('software')}</a>
                  </div>
              </div>

              <div>
                  <p class="font-semibold text-gray-800 dark:text-white">{t('banner-contact-btn')}</p>

                  <div class="flex flex-col items-start mt-5 space-y-2">
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">+383 49 400-007 / +383 49 770-042</a>
                      <a href="#" class="text-gray-600 transition-colors duration-300 dark:text-gray-300 dark:hover:text-blue-400 hover:underline hover:text-blue-500">info@urbantech-ks.com</a>
                  </div>
              </div>
          </div>

          <hr class="my-6 border-gray-200 md:my-10 dark:border-gray-700" />

          <div class="flex flex-col items-center justify-between sm:flex-row">
              <a href="#">
                  <img class="w-auto h-7" src={logo} alt="" />
              </a>

              <p class="mt-4 text-sm text-gray-500 sm:mt-0 dark:text-gray-300">{t('all-rights-reserved')}</p>
          </div>
      </div>
    </footer>
  )
}

export default Footer;